//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-272:_8688,_4776,_9358,_5136,_2760,_9568,_6584,_3448;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-272')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-272', "_8688,_4776,_9358,_5136,_2760,_9568,_6584,_3448");
        }
      }catch (ex) {
        console.error(ex);
      }