
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage navUser-action custom' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button shopcart-dropdown block-cart-link',
        'title': 'Garage: ' + this.size + ' item(s)',
        'tabIndex': '0',
        'role': 'button'
    }, _createElement('a', { 'className': 'garage-icon-container block-link link-dropdown' }, _createElement('span', { 'className': 'flaticon-car' }), _createElement('span', {
        'className': 'count',
        'key': 'garage-size'
    }, this.size))), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('li', { 'className': 'garage-item woocommerce-mini-cart-item mini_cart_item' }, _createElement('span', {
                            'className': 'select-model',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('span', {
                            'className': 'remove-model remove remove_from_cart_button',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 22 22',
                                'key': '2900'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'widget woocommerce widget_shopping_cart cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '71'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'scroll-wrapper woocommerce-mini-cart cart_list product_list_widget' }, _createElement('ul', { 'className': 'woocommerce-mini-cart cart_list product_list_widget scroll-content' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]))), _createElement('div', { 'className': 'garage-buttons-container' }, _createElement('div', {
                'className': 'clear-garage woocommerce-mini-cart__buttons buttons',
                'data-cm-role': 'clear-garage'
            }, _createElement('a', { 'className': 'button checkout wc-forward' }, window.themeSettings.locales.clear_garage)), [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle woocommerce-mini-cart__buttons buttons cm_addButton' }, _createElement('a', { 'className': 'button checkout wc-forward' }, window.themeSettings.locales.add_vehicle));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]))) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '2008'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'scroll-wrapper woocommerce-mini-cart cart_list product_list_widget' }, _createElement('ul', { 'className': 'woocommerce-mini-cart cart_list product_list_widget scroll-content' }, _createElement('li', { 'className': 'vehicle-advantages woocommerce-mini-cart-item mini_cart_item' }, '\n          ', window.themeSettings.locales.garage_first_line, '\n        '), _createElement('li', { 'className': 'vehicle-advantages woocommerce-mini-cart-item mini_cart_item' }, '\n          ', window.themeSettings.locales.garage_second_line, '\n        '), _createElement('li', { 'className': 'vehicle-advantages woocommerce-mini-cart-item mini_cart_item' }, '\n          ', window.themeSettings.locales.garage_third_line, '\n        '))), [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle woocommerce-mini-cart__buttons buttons cm_addButton' }, _createElement('a', { 'className': 'button checkout wc-forward' }, window.themeSettings.locales.add_vehicle));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:addButton","cm:addButton","cm:garageDropdown"]