const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const wheelsAndTiresFields = [...wheelsFields, ...tiresFields, 'wheel_backspace'];
const facetBarFields = [...wheelsFields, ...tiresFields];
const ignoreFields = [...facetBarFields];

const searchPageUrl = '/search';
const brandSelectionPageUrl = '/pages/shop-by-brand';
const categorySelectionPageUrl = '/collections';

const fieldsForRelated = ['category', 'collections', ...wheelsAndTiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

globalThis.Convermax.facetPanelIgnoreField = (field) => {
  return (
    (window.Convermax.universalCollection && field === 'Fitment') ||
    (globalThis.innerWidth >= 768 && facetBarFields.includes(field))
  );
};

globalThis.Convermax.productIsNew = (publishedAt) => {
  return (Date.now() - new Date(publishedAt)) / (1000 * 60 * 60 * 24) < window.themeSettings.time_new;
};

function getFitmentSearchTitle() {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = document.querySelector('body.template-collection h1.page-heading')?.textContent.trim();
  return pageType === 'collection' ? title : pageType === 'vendors' ? `${title} Parts` : 'Parts';
}

globalThis.Convermax.showConvermaxResults =
  window.document.querySelectorAll('.collection-template-items-container .product-item').length === 0 ||
  window.document.body.classList.contains('cm_search');

function responseHandler(response) {
  if (window.document.querySelector('#shopify-section-collection-template')) {
    const originalCategoryListing = window.document.querySelector('#cm_collection-page');

    const { selection, query, sort, pageSize } = window.Convermax.getSearchRequest();
    const isVehicleSelected = window.Convermax.isVehicleSelected();

    if (
      !window.Convermax.showConvermaxResults &&
      window.document.body.classList.contains('cm_search-hide') &&
      (isVehicleSelected ||
        selection.length > 0 ||
        query.length > 0 ||
        sort !== 'relevance' ||
        pageSize !== 36 ||
        window.document.querySelector('.cmTemplate_timeout') ||
        window.document.querySelector('.cmTemplate_networkError'))
    ) {
      if (originalCategoryListing) {
        originalCategoryListing.style.display = 'none';
      }

      window.document.body.classList.remove('cm_search-hide');

      globalThis.Convermax.showConvermaxResults = true;
    }
  }

  return response;
}

function InitFunc() {
  if (window.document.querySelector('#shopify-section-collection-template')) {
    window.document.body.classList.add('cm_search-hide');
  }
}

const updateCallback = () => {
  const currentCurrency = window.Currency.cookie.read();
  if (currentCurrency) {
    window.Currency.convertAll(window.Shopify.currency.active, currentCurrency);
  }

  window.updateWishListButtons();
  window.quickView();

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  searchPageUrl,
  InitFunc,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  requestTimeout: window.Convermax.config?.requestTimeout,
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: categorySelectionPageUrl,
        field: window.Convermax.config?.categorySelectionPageField ?? 'category',
      },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: window.themeSettings?.enable_ymm_redirect ?? true,
    getFitmentSearchTitle,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { firstChildOf: '.collection-template-items-container', class: 'cm_main-content' },
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: '#cm_collection-facet-panel',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories-page',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'SearchBoxTop',
      type: 'SearchBox',
      location: '.header-wrap-stick .block-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxNav',
      type: 'SearchBox',
      location: '.header-nav .block-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: '.meta-woo .block-search-bar .block-link',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '#shopify-section-product-template .related-product .wrap-product',
        class: 'cm_related-items',
      },
      template: 'product/relatedItems',
      count: window.Convermax.productRecommendationsSettings?.limit || 6,
      getSelection,
    },
    {
      name: 'Garage',
      location: {
        selector: '#cm_Garage',
        class: 'block-minicart block-woo azirspares-mini-cart azirspares-dropdown',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.home-ymm-bar',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '#cm_fitment-table_tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '.tab-content #cm_fitmentTable',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
