
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'href': '#engo-pd-tab-fitment-table',
        'data-toggle': 'tab',
        'key': '0'
    }, '\n  ', window.themeSettings.locales.ymm.fitment_table_title || 'Fitment', '\n') : null;
}
        export const componentNames = []